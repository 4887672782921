import { animated } from '@/components/animated/animated.js';
import { styled } from '@/stitches/index.js';
import { ChevronUpIcon } from '@contra/ui-kit/icons';
import { type HTMLAttributes } from 'react';

type TextFieldChevronProps = HTMLAttributes<HTMLButtonElement> & {
  readonly isOpen: boolean;
};

const Div = styled(animated.div, {
  position: 'absolute',
  right: '$16',
  top: '$16',
});

export const TextFieldChevron = ({ isOpen }: TextFieldChevronProps) => {
  return (
    <Div
      animate={{
        rotate: isOpen ? 0 : 180,
      }}
      initial={false}
      transition={{ damping: 30, stiffness: 500, type: 'spring' }}
    >
      <ChevronUpIcon
        focusable={false}
        role="img"
        size={28}
      />
    </Div>
  );
};

TextFieldChevron.displayName = 'TextField.Chevron';
