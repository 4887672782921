import { styled } from '@/stitches/index.js';
import { CloseIcon } from '@contra/ui-kit/icons';

const StyledClearButton = styled('button', {
  '& > svg': {
    display: 'inline-block',
  },
  '&:hover, &:focus': {
    backgroundColor: '$gray20',
  },
  borderRadius: '$4',
  color: '$gray60',
  lineHeight: '1',
  padding: '$4',
});

export const TextFieldClearButton = ({
  onClick,
}: {
  readonly onClick: () => void;
}) => {
  return (
    <StyledClearButton
      aria-label="Clear input"
      onClick={onClick}
      type="button"
    >
      <CloseIcon
        focusable={false}
        size={24}
      />
    </StyledClearButton>
  );
};
