import { animated } from '@/components/animated/animated.js';
import { styled } from '@/stitches/index.js';
import { ChevronUpIcon } from '@contra/ui-kit/icons';
import {
  forwardRef,
  type HTMLAttributes,
  type MouseEvent,
  type Ref,
} from 'react';

const Button = styled('button', {
  '& > svg': {
    display: 'inline-block',
  },
  '&:hover, &:focus': {
    backgroundColor: '$gray20',
  },
  borderRadius: '$4',
  lineHeight: '1',
  padding: '$4',
});

type TextFieldButtonProps = HTMLAttributes<HTMLButtonElement> & {
  readonly isOpen: boolean;
};

export const TextFieldButton = forwardRef(
  (
    { isOpen, onClick, ...props }: TextFieldButtonProps,
    ref: Ref<HTMLButtonElement>,
  ) => {
    return (
      <Button
        onClick={(event: MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          event.stopPropagation();
          onClick?.(event);
        }}
        ref={ref}
        type="button"
        {...props}
      >
        <animated.div
          animate={{
            rotate: isOpen ? 0 : 180,
          }}
          initial={false}
          transition={{ type: 'spring' }}
        >
          <ChevronUpIcon
            focusable={false}
            role="img"
            size={24}
          />
        </animated.div>
      </Button>
    );
  },
);
